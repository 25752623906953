import Cookies from 'js-cookie';
import config from '@/config';
import esApiAuth from '@/service/esapiAuth.service';
import exception from '@/service/exception.service';

const api = {
  googleAuth: () => 'v3/auth/google-boh/login',
  magicLink: (userId) => `auth/magiclink/${userId}`,
};

export default {
  getCookieDomain() {
    return config.mode === 'local' ? '' : '.86repairs.com';
  },
  getCookieName() {
    return config.apiToken;
  },
  clearAuthToken() {
    return Cookies.set(config.apiToken, '', { domain: this.getCookieDomain() });
  },
  getAuthToken() {
    return Cookies.get(config.apiToken);
  },
  hasAuthToken() {
    return !!this.getAuthToken();
  },
  getGoogleEndpoint(nextRoute) {
    const route = `${api.googleAuth()}?nextRoute=${
      nextRoute || config.bohV2Url
    }`;
    return new URL(route, config.esApiUrl).toString();
  },
  getMagicLink(userId) {
    return esApiAuth
      .get(api.magicLink(userId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while getting the magic link.',
        );
      });
  },
};

import { defineStore } from 'pinia';
import assetTypesService from '@/service/asset-types.service';

export const useAssetTypesStore = defineStore('assetTypes', {
  state: () => ({
    allAssetTypes: [],
    queriedAssetTypes: [],
  }),
  actions: {
    async getAllAssetTypes() {
      const types = await assetTypesService.getAllAssetTypes();
      this.allAssetTypes = types;
    },
    async getAssetTypesByQuery(query) {
      const response = await assetTypesService.getAssetTypesByQuery(query);
      this.queriedAssetTypes = response.data;
    },
    async addToAssetTypes(query) {
      const response = await assetTypesService.getAssetTypesByQuery(query);
      this.queriedAssetTypes.push(...response.data);
    },
    async patchAssetType({ assetTypeId, changes }) {
      await assetTypesService.updateAssetType(assetTypeId, changes);
    },
  },
});

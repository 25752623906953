import amplitude from 'amplitude-js';
import config from '@/config';

if (config.amplitudeEnabled) {
  amplitude.getInstance().init(config.amplitudeKey);
} else {
  // eslint-disable-next-line no-console
  console.log('Amplitude disabled');
}

export default {
  setUserId(userId) {
    if (!config.amplitudeEnabled) {
      return;
    }

    amplitude.getInstance().setUserId(userId);
    if (userId == null) {
      amplitude.getInstance().regenerateDeviceId();
    }
  },
  setUserProperties(properties) {
    if (!config.amplitudeEnabled) {
      return;
    }

    amplitude.getInstance().setUserProperties(properties);
  },
  logEvent(eventName, eventData) {
    if (!config.amplitudeEnabled) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      amplitude.getInstance().logEvent(
        eventName,
        eventData,
        () => resolve(),
        () => reject(),
      );
    });
  },
  logErrorEvent(error, customProps) {
    const eventProps = customProps ?? {};
    if (error.message) {
      eventProps.message = error.message;
    }
    if (error.response) {
      eventProps.statusCode = error.response.status;
    }
    if (error.config) {
      eventProps.url = `${error.config.baseURL}/${error.config.url}`;
    }
    return this.logEvent('API Error', eventProps);
  },
};

import axios from 'axios';
import router from '@/router';
import config from '@/config';
import authService from '@/service/auth.service';

const esApiAuth = axios.create({
  baseURL: new URL('v3/', config.esApiUrl).toString(),
  withCredentials: true,
});

esApiAuth.interceptors.request.use(
  (request) => {
    if (!authService.hasAuthToken()) {
      router.go(0);
    }
    return request;
  },
  (error) => Promise.reject(error),
);

esApiAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      authService.clearAuthToken();
      router.go(0);
    }
    return Promise.reject(error);
  },
);

export default esApiAuth;

import { defineStore } from 'pinia';
import config from '@/config';
import userProfilesService from '@/service/user-profiles.service';
import _ from 'lodash';
import { useNavStore } from './navStore';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    configMode: config.mode,
    configVersion: config.version,
    configCustomerPortalUrl: config.customerPortal,
    configPartsTownPortalUrl: config.partsTownPortal,
    bohV2Url: config.bohV2Url,
    userInfo: {},
    userId86RepairsSystem: '86RepairsSystem',
  }),
  actions: {
    async getUserInfo() {
      const navStore = useNavStore();
      const results = await userProfilesService.getUserInfo();
      this.userInfo = results;
      navStore.setPreferences(results.bohPreferences ?? {});
    },
  },
  getters: {
    hasUserInfo: (state) => !_.isEmpty(state.userInfo),
    hasAdminPermission: (state) =>
      !!state.userInfo?.bohRoles?.find((role) => role.bohRole === 'isAdmin'),
    hasAccountManagerPermission: (state) =>
      !!state.userInfo?.bohRoles?.find(
        (role) => role.bohRole === 'isAccountManager',
      ),
    hasOnboardingPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isOnboardManager',
      ),
    hasRequestPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isRequestAssignee',
      ),
    hasTaskPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isTaskAssignee',
      ),
    hasVendorOnboardPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isSPOnboardManager',
      ),
    hasTroubleshootingPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isTroubleshootingManager',
      ),
    hasCustomerNotesPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isCustomerNotesManager',
      ),
    hasVendorNotesPermission: (state) =>
      !!state.userInfo.bohRoles.find(
        (role) => role.bohRole === 'isVendorNotesManager',
      ),
    isNotProd: (state) => state.configMode !== 'production',
    isProd: (state) => state.configMode === 'production',
    isLocal: (state) => state.configMode === 'local',
    currentUserId: (state) => state.userInfo.id,
    currentUserEmail: (state) => state.userInfo.email,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const getAllPaginatedItems = async (
  endpoint,
  query = {},
  limit = 100,
  page = 0,
  allItems = [],
) => {
  const response = await endpoint({
    ...query,
    limit,
    offset: limit * page,
  });
  const { count, data } = response;
  const newAllItems = [...allItems, ...data];

  if (newAllItems?.length >= count || data?.length === 0) {
    return newAllItems;
  }
  return getAllPaginatedItems(endpoint, query, limit, page + 1, newAllItems);
};

export const getAllPaginatedItemsWithArgs = async (
  endpoint,
  args,
  query = {},
  limit = 100,
  page = 0,
  allItems = [],
) => {
  const response = await endpoint(args, {
    ...query,
    limit,
    offset: limit * page,
  });
  const { count, data } = response;
  const newAllItems = [...allItems, ...data];

  if (newAllItems?.length >= count) {
    return newAllItems;
  }
  return getAllPaginatedItemsWithArgs(
    endpoint,
    args,
    query,
    limit,
    page + 1,
    newAllItems,
  );
};

export const getAllPaginatedData = async (
  endpoint,
  query = {},
  limit = 100,
  page = 0,
  allItems = [],
) => {
  const response = await endpoint({
    ...query,
    limit,
    offset: limit * page,
  });
  const { count, data } = response;
  const newAllItems = [...allItems, ...data];

  if (newAllItems?.length >= count) {
    return {
      count,
      data: newAllItems,
    };
  }
  return getAllPaginatedItems(endpoint, query, limit, page + 1, newAllItems);
};

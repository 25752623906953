import analyticsService from '@/service/analytics.service';
import { useSnackbarStore } from '@/stores/snackbarStore';

function handleInvoiceError(exception) {
  const metadata = exception?.response?.data?.metadata;

  function buildMessageWithLinks(invoiceId, requestId) {
    if (invoiceId && requestId) {
      return `This invoice is a duplicate of invoice <a target="_blank" href="/invoices/transcribe/${invoiceId}">${invoiceId}</a> on request <a href="/request/${requestId}">${requestId}</a>.`;
    }

    if (invoiceId) {
      return `This invoice is a duplicate of invoice <a target="_blank" href="/invoices/transcribe/${invoiceId}">${invoiceId}</a>.`;
    }

    return null;
  }

  if (metadata?.skippedProcessingReason === 'HASH_EXISTS') {
    return buildMessageWithLinks(
      metadata.invoice?.id,
      metadata.invoice?.requestId,
    );
  }

  return null;
}

export default {
  show: (ex) => {
    const snackbarStore = useSnackbarStore();
    snackbarStore.generalError();
    analyticsService.logErrorEvent(ex);
    throw ex;
  },
  showCustomError: (ex, errorMsg, customErrorProps) => {
    const snackbarStore = useSnackbarStore();
    snackbarStore.customError(
      handleInvoiceError(ex) || ex?.response?.data?.message || errorMsg,
    );
    if (ex) {
      analyticsService.logErrorEvent(ex, customErrorProps);
      throw ex;
    }
  },
};

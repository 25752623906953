import { defineStore } from 'pinia';
import {
  pmActivityTypes,
  autoSendActivityTypes,
  taskTypes,
  allActivityTypes,
  telephonyActivityTypes,
} from '@/constants/activities';
import exceptionService from '@/service/exception.service';
import activityService from '@/service/activity.service';
import { useRequestDetailStore } from '@/stores/requestDetailStore';
import dayjs from 'dayjs';

export const useActivityStore = defineStore('activity', {
  state: () => ({
    pmActivityTypes,
    autoSendActivityTypes,
    taskTypes,
    allActivityTypes,
    activityTypesForTaskBar: [
      'Follow up',
      'Vendor Follow Up',
      'Request Dispatch',
      'Request Quote',
      'Dispatch Confirmation',
      'Update ETA Email',
      'Purchased parts',
      'Add Quote',
      'Resolution',
      'Troubleshooting',
      'Warranty check',
      'Other',
      'Log Savings',
    ],
    activityTypesForLoggingTaskBar: [
      'Follow up',
      'Update ETA Email',
      'Purchased parts',
      'Quote',
      'Resolution',
      'Troubleshooting',
      'Warranty check',
      'Other',
      'Log Savings',
    ],
    totalActivityCount: 0,
    activities: [],
    activityIsSaving: false,
    activityLogSummary: null,
    activityLogFilters: {
      sortBy: 'Newest Activity First',
      activityType: 'All Activity Types',
      userId: -1,
    },
  }),
  actions: {
    async getActivitiesForRequest({
      requestId,
      params = {
        page: 0,
        limit: 300, // TODO: revert to 10 later when we fix all places that rely on all activities
        offset: 0,
        orderBy: 'createdAt',
        orderDirection: 'DESC',
      },
      resetFilters = true,
    }) {
      if (!requestId) {
        exceptionService.showCustomError(
          null,
          'Unable to get all activities for undefined request ID.',
        );
        return;
      }
      const requestDetailStore = useRequestDetailStore();
      const { isTypeIncident } = requestDetailStore;
      const relations = [
        'dispatchConfirmation',
        'requestQuote',
        'vendorFollowUp',
        'dispatchApproval',
        'quoteApproval',
        'emails',
        'chats',
        'conferences',
      ];
      if (!isTypeIncident) {
        relations.push('vendorScheduledCommunication');
      }
      const allActivities = await activityService.getActivitiesByQuery({
        requestId,
        ...params,
        withDeleted: true,
        relations: ['createdByUser', ...relations],
      });
      const notDeletedActivities = allActivities.data.filter(
        (a) => !a.deletedAt,
      );
      const formattedActivities = notDeletedActivities.map((item) => {
        const itemCopy = { ...item };
        itemCopy.isEditing = false;
        itemCopy.editedDescription = item.description;
        return itemCopy;
      });
      if (params && params.page > 1) {
        this.activities.push(...formattedActivities);
      } else {
        const summary = await activityService.getActivityLogSummary(requestId);
        this.activityLogSummary = summary;
        this.activities = formattedActivities;
        this.totalActivityCount = notDeletedActivities.count;
      }
      if (resetFilters) {
        this.activityLogFilters = {
          sortBy: 'Newest Activity First',
          activityType: 'All Activity Types',
          userId: -1,
        };
      }
    },
    async deleteActivity(activityId) {
      this.activityIsSaving = true;
      await activityService.deleteActivity(activityId);
    },
    async updateActivity({ activityId, description }) {
      this.activityIsSaving = true;
      await activityService.updateActivity(activityId, description);
    },
    async saveActivity({ requestId, activity }) {
      await activityService.createActivity(requestId, activity);
      this.getActivitiesForRequest({ requestId });
    },
  },
  getters: {
    resolutionActivities: (state) =>
      state.activities.filter(
        (it) => it.activityType === state.allActivityTypes.RESOLUTION,
      ),
    dispatchConfirmationActivities: (state) =>
      state.activities
        .filter(
          (it) =>
            it.activityType === state.allActivityTypes.DISPATCH_CONFIRMATION,
        )
        .filter((it) => it.new_task_details)
        .map((it) => it.new_task_details),
    dispatchConfirmationActivityObjects: (state) =>
      state.activities.filter(
        (it) =>
          it.activityType === state.allActivityTypes.DISPATCH_CONFIRMATION,
      ),
    requestQuoteActivityObjects: (state) =>
      state.activities.filter(
        (it) => it.activityType === state.allActivityTypes.REQUEST_QUOTE,
      ),
    dispatchApprovalActivityObjects: (state) =>
      state.activities.filter(
        (it) => it.activityType === state.allActivityTypes.DISPATCH_APPROVAL,
      ),
    quoteApprovalActivityObjects: (state) =>
      state.activities.filter(
        (it) => it.activityType === state.allActivityTypes.QUOTE_APPROVAL,
      ),
    troubleshootingActivities: (state) =>
      state.activities.filter(
        (it) => it.activityType === state.allActivityTypes.TROUBLESHOOTING,
      ),
    telephonyActivities: (state) =>
      state.activities.filter((it) =>
        Object.values(telephonyActivityTypes).includes(it.activityType),
      ),
    emailActivities: (state) =>
      state.activities.filter((it) => !!it.emails && it.emails.length > 0),
    confirmNextOccurrenceActivities: (state) =>
      state.activities?.filter(
        (it) =>
          it.activityType === state.allActivityTypes.CONFIRM_NEXT_OCCURRENCE,
      ),
    vendorScheduledCommunicationActivities: (state) =>
      state.activities.filter((it) => it.vendorScheduledCommunication),
    customerSuggestedServiceWindow: (state) => {
      const copyOfActivities = state.activities.filter(
        (it) => it.vendorScheduledCommunication,
      );
      if (copyOfActivities.length === 0) {
        return '';
      }
      const activityWithAlternateDate = copyOfActivities
        ?.filter(
          (activity) =>
            activity.vendorScheduledCommunication?.alternativeStartDate,
        )
        ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0];
      const format = (dateString) =>
        dayjs(dateString).utc().format('MM/DD/YYYY');
      if (
        !activityWithAlternateDate?.vendorScheduledCommunication
          ?.alternativeStartDate
      ) {
        return '';
      }
      if (
        !activityWithAlternateDate.vendorScheduledCommunication
          ?.alternativeEndDate
      ) {
        return format(
          activityWithAlternateDate?.vendorScheduledCommunication
            ?.alternativeStartDate,
        );
      }
      return `${format(
        activityWithAlternateDate?.vendorScheduledCommunication
          ?.alternativeStartDate,
      )} - ${format(
        activityWithAlternateDate?.vendorScheduledCommunication
          ?.alternativeEndDate,
      )}`;
    },
    pmActivityTypesForTaskBar: () => {
      const requestDetailStore = useRequestDetailStore();
      const response = ['Other'];
      const { canReschedulePmVisit } = requestDetailStore;
      if (canReschedulePmVisit) {
        response.push('Reschedule Vendor Visit');
      }
      return response;
    },
    activityTypesForTaskFilters: (state) => ({
      ...state.taskTypes,
      ...state.pmActivityTypes,
    }),
  },
});

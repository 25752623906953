import { defineStore } from 'pinia';
import { getAllPaginatedItems } from '@/utils';
import taskService from '@/service/task.service';
import exceptionService from '@/service/exception.service';
import vendorService from '@/service/vendor.service';
import { useActivityStore } from '@/stores/activityStore';
import {
  autoSendActivityTypes,
  allActivityTypes,
} from '@/constants/activities';

export const useTaskStore = defineStore('tasks', {
  state: () => ({
    upcomingTasks: [],
    focusedTask: null,
    vendorsForResolutionTask: [{ name: '86 Repairs' }, { name: 'Customer' }],
    isLoadingVendorsForResolution: false,
  }),
  actions: {
    async getTasks(params) {
      const tasks = await getAllPaginatedItems(
        taskService.getTasksByQuery,
        params,
      );
      return tasks;
    },
    async getTasksForRequest(requestId) {
      if (!requestId) {
        exceptionService.showCustomError(
          null,
          'Unable to get all tasks for undefined request ID.',
        );
        return;
      }
      let allTasks = await getAllPaginatedItems(taskService.getTasksByQuery, {
        requestId,
        completed: false,
        relations: [
          'assignedToUser',
          'vendor',
          'vendor.vendorDefinedEta',
          'autoEmailDetail',
          'triggerJobs',
          'triggerJobs.userProfiles',
        ],
      });
      allTasks = allTasks.map((it) => ({
        ...it,
        isSelected: false,
        isEditing: false,
        showEscalate: false,
        isDeletingAutoTask: false,
      }));
      this.upcomingTasks = allTasks;
    },
    async getDispatchedVendorsForRequest(requestId) {
      this.isLoadingVendorsForResolution = true;
      const vendors = await vendorService.getDispatchedVendorsForRequest(
        requestId,
      );
      if (vendors) {
        this.vendorsForResolutionTask.push(...vendors);
      }
      this.isLoadingVendorsForResolution = false;
    },
    async getVendorsForInvoiceTask(requestId) {
      const vendors = await vendorService.getDispatchedVendorsForRequest(
        requestId,
      );
      return vendors;
    },
    async createNewTask(newTask) {
      const createdTask = await taskService.createTask(newTask);
      await this.getTasksForRequest(newTask.requestId);
      return createdTask.id;
    },
    async patchTask({ requestId, taskId, changes }) {
      await taskService.updateTask(taskId, changes);
      this.refreshTasksAndActivities({ requestId });
    },
    async refreshTasksAndActivities({ requestId }) {
      const activityStore = useActivityStore();
      await activityStore.getActivitiesForRequest({ requestId });
      await this.getTasksForRequest(requestId);
    },
    async completeTask({ requestId, body, taskId }) {
      const activityStore = useActivityStore();
      try {
        await taskService.completeTask(body, taskId);
        await activityStore.getActivitiesForRequest({ requestId });
        await this.getTasksForRequest(requestId);
      } catch (_error) {
        // exception service catches and logs api errors, but it also re-throws those errors so we need an empty try/catch here
      }
    },
    async save86Task({ body, taskId }) {
      try {
        await taskService.save86Task(body, taskId);
      } catch (_error) {
        // exception service catches and logs api errors, but it also re-throws those errors so we need an empty try/catch here
      }
    },
    async blockTask({ taskId, params }) {
      await taskService.blockTask(taskId, params);
    },
    async unblockTask({ taskId, params }) {
      await taskService.unblockTask(taskId, params);
    },
    async releaseTask({ taskId, params }) {
      await taskService.releaseTask(taskId, params);
    },
    async escalateTask({ taskId, params }) {
      await taskService.escalateTask(taskId, params);
    },
    async deescalateTask({ taskId, params }) {
      await taskService.deescalateTask(taskId, params);
    },
    async deleteTask(taskId) {
      await taskService.deleteTask(taskId);
    },
    openTask() {
      const taskToSelect = this.upcomingTasks.find(
        (it) => it.activityType === 'Vendor Dispatched Email',
      );
      taskToSelect.isSelected = true;
    },
    async updateEmailInfo({ taskId, params }) {
      await taskService.updateTask(taskId, params);
    },
    async setFocusedTask(task) {
      this.focusedTask = task;
    },
  },
  getters: {
    autoCloseTaskExists: (state) =>
      state.upcomingTasks.some(
        (it) => it.activityType === autoSendActivityTypes.REQUEST_AUTO_CLOSE,
      ),
    requestQuoteTaskObjects: (state) =>
      state.upcomingTasks.filter(
        (it) => it.activityType === allActivityTypes.REQUEST_QUOTE,
      ),
  },
});

import { defineStore } from 'pinia';
import userProfilesService from '@/service/user-profiles.service';
import { getAllPaginatedItems } from '@/utils';

export const useUserPermissionsStore = defineStore('userPermissions', {
  state: () => ({
    users: [],
    requestAssignees: [],
    taskAssignees: [],
    accountManagers: [],
    pagination: {
      limit: 12,
      offset: 0,
      count: 0,
    },
    filter: {
      isBohUser: true,
      orderBy: 'name',
      orderDirection: 'ASC',
    },
    loading: false,
  }),
  actions: {
    patchUserPermission({ userId, bohRoles }) {
      const user = this.users.find((u) => u.id === userId);
      user.bohRoles = bohRoles;
      return userProfilesService.updateUserProfile(userId, {
        isBohUser: true,
        bohRoles,
      });
    },
    archiveUserById(userId) {
      return userProfilesService
        .updateUserProfile(userId, { isBohUser: true, archivedAt: new Date() })
        .then(() => {
          this.getPaginatedBohUsers();
        });
    },
    async getUsersByPermission(permissionType) {
      const users = await getAllPaginatedItems(
        userProfilesService.getUserProfilesByQuery,
        {
          isBohUser: true,
          orderBy: 'name',
          orderDirection: 'ASC',
          bohRole: permissionType,
          relations: 'bohRoles',
          archived: false,
        },
      );
      let permission;
      if (permissionType === 'isRequestAssignee') {
        permission = 'requestAssignees';
      } else if (permissionType === 'isTaskAssignee') {
        permission = 'taskAssignees';
      } else if (permissionType === 'isAccountManager') {
        permission = 'accountManagers';
      } else {
        permission = permissionType;
      }
      this[permission] = users;
    },
    async setFilter(filter) {
      this.filter = { ...this.filter, ...filter };
    },
    async getPaginatedBohUsers() {
      this.loading = true;
      userProfilesService
        .getUserProfilesByQuery({
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...this.filter,
          archived: false,
        })
        .then((result) => {
          this.users = result.data;
          this.pagination.count = result.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  getters: {
    hasUsersByPermission: (state) =>
      state.requestAssignees.length > 0 && state.taskAssignees.length > 0,
    csmUsers: (state) => [
      ...new Set([...state.requestAssignees, ...state.taskAssignees]),
    ],
  },
});

import { defineStore } from 'pinia';
import customerService from '@/service/customer.service';
import taskService from '@/service/task.service';
import { getAllPaginatedItems } from '@/utils';
import userProfilesService from '@/service/user-profiles.service';
import { useSnackbarStore } from '@/stores/snackbarStore';

export const useTaskDistributionStore = defineStore('taskDistribution', {
  state: () => ({
    totalTasks: 0,
    options: {
      sortBy: ['assignedTo'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 100,
    },
    tasks: [],
    loading: false,
    isSaving: false,
    assigneesWithTasks: [],
    assignees: [],
    assigneeFilter: [],
    taskTypes: [],
    taskTypeFilter: [],
    selectedTasks: [],
    customers: [],
    customerFilter: [],
    distributeToList: [],
  }),
  actions: {
    getTasks() {
      const params = {
        offset: this.options.itemsPerPage * (this.options.page - 1),
        limit: this.options.itemsPerPage,
        isCompleted: false,
      };
      if (this.assigneeFilter.length > 0) {
        params.assignedTo = this.assigneeFilter;
      }
      if (this.taskTypeFilter.length > 0) {
        params.activityType = this.taskTypeFilter;
      }
      if (this.customerFilter.length > 0) {
        params.customerId = this.customerFilter;
      }

      // Don't try to load more pages if loading
      if (!this.loading) {
        this.loading = true;
        return taskService
          .getTaskSummaries(params)
          .then((results) => {
            if (
              Math.floor(results.offset / this.options.itemsPerPage) + 1 ===
              this.options.page
            ) {
              this.tasks.push(...results.data);
              this.options.page += 1;
            }
            this.selectedTasks.push(...results.data);
            this.totalTasks = results.count;
          })
          .finally(() => {
            this.loading = false;
          });
      }
      return false;
    },
    resetPage() {
      return new Promise(() => {
        this.options.page = 1;
        this.tasks = [];
        this.selectedTasks = [];
      });
    },
    getAssigneesWithTasks() {
      return userProfilesService.getAssigneesWithTasks().then((results) => {
        this.assigneesWithTasks = results.sort((a, b) =>
          a.name < b.name ? -1 : 1,
        );
      });
    },
    getAssignees() {
      return getAllPaginatedItems(userProfilesService.getUserProfilesByQuery, {
        isBohUser: true,
        bohRole: 'isTaskAssignee',
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: 'bohRoles',
      }).then((results) => {
        this.assignees = results;
      });
    },
    setAssigneeFilter(item) {
      return new Promise(() => {
        this.assigneeFilter = item;
      });
    },
    getTaskTypes() {
      return taskService.getTaskTypes().then((results) => {
        this.taskTypes = results;
      });
    },
    setTaskTypeFilter(item) {
      this.taskTypeFilter = item;
    },
    getCustomers() {
      return getAllPaginatedItems(customerService.getCustomersByQuery).then(
        (results) => {
          this.customers = results;
        },
      );
    },
    setCustomerFilter(item) {
      this.customerFilter = item;
    },
    setDistributeList(value) {
      this.distributeToList = value;
    },
    distributeTasks() {
      const snackbarStore = useSnackbarStore();
      this.isSaving = true;
      const postBody = {
        assigneeIds: this.distributeToList,
        taskIds: this.selectedTasks.map((item) => item.id),
      };
      return taskService.distributeTasks(postBody).then(() => {
        this.getAssigneesWithTasks().then(() => {
          this.setAssigneeFilter(this.distributeToList).then(() => {
            this.setDistributeList([]);
          });
        });
        this.resetPage().then(() => {
          this.getTasks().then(() => {
            this.isSaving = false;
            snackbarStore.customSuccess(
              `Successfully distributed ${postBody.taskIds.length} tasks to ${postBody.assigneeIds.length} users.`,
            );
          });
        });
      });
    },
  },
});

import axios from 'axios';
import router from '@/router';
import config from '@/config';
import authService from '@/service/auth.service';

const esApiV3 = axios.create({
  baseURL: new URL('v3/boh', config.esApiUrl).toString(),
  withCredentials: true,
});

esApiV3.interceptors.request.use(
  (request) => {
    if (!authService.hasAuthToken()) {
      router.go(0);
    }
    // eslint-disable-next-line no-restricted-globals, no-param-reassign
    request.headers['current-page'] = location.pathname;
    return request;
  },
  (error) => Promise.reject(error),
);

esApiV3.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      authService.clearAuthToken();
      router.go(0);
    }
    return Promise.reject(error);
  },
);

export default esApiV3;

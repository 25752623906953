import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  assets: () => 'assets',
  assetById: (id) => `assets/${id}`,
  assetDetailsById: (id) => `assets/${id}/detailView`,
  assetServiceVisits: (id) => `assets/${id}/service-visits`,
  assetSummaries: () => 'assets/summaries',
  modelNumbers: () => 'assets/modelNumbers',
  warrantyReview: () => 'assets/warrantyReview',
  pmAssets: () => 'assets/pmAssets',
  assetSpending: () => 'assets/asset-spending',
};

export default {
  getAssetsByQuery(query) {
    return esApiV3
      .get(api.assets(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get assets',
        );
      });
  },
  getAssetSummaries(query) {
    return esApiV3
      .get(api.assetSummaries(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get asset summaries',
        );
      });
  },
  getAssetById(assetId, query) {
    return esApiV3
      .get(api.assetById(assetId), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get asset with id ${assetId}.`,
        );
      });
  },
  getAssetDetailsById(assetId) {
    return esApiV3
      .get(api.assetDetailsById(assetId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get asset details with id ${assetId}.`,
        );
      });
  },
  getAssetServiceVisits(assetId) {
    return esApiV3
      .get(api.assetServiceVisits(assetId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get asset service visits for asset ${assetId}.`,
        );
      });
  },
  updateAsset(assetId, body) {
    return esApiV3
      .patch(api.assetById(assetId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to update asset with id ${assetId}.`,
        );
      });
  },
  updateManyAssets(body) {
    return esApiV3
      .patch(api.assetSpending(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to update assets',
        );
      });
  },
  createAsset(body) {
    return esApiV3
      .post(api.assets(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create new asset',
        );
      });
  },
  deleteAsset(id) {
    return esApiV3
      .delete(api.assetById(id))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to delete asset with id ${id}.`,
        );
      });
  },
  async getAssetModelNumbers(query) {
    return esApiV3
      .get(api.modelNumbers(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get asset model numbers',
        );
      });
  },
  getAssetsForWarrantyReview(query) {
    return esApiV3
      .get(api.warrantyReview(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get assets for warranty review',
        );
      });
  },
  getPmAssetsByQuery(query) {
    return esApiV3
      .get(api.pmAssets(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get PM assets with chores',
        );
      });
  },
};

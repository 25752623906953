import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  assets: () => 'asset-types',
  assetTypeById: (id) => `asset-types/${id}`,
  all: () => 'asset-types/all',
};

export default {
  getAssetTypesByQuery(query) {
    return esApiV3
      .get(api.assets(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get asset types.',
        );
      });
  },
  getAssetTypeById(id) {
    return esApiV3
      .get(api.assetTypeById(id))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get asset type with id ${id}.`,
        );
      });
  },
  updateAssetType(assetTypeId, body) {
    return esApiV3
      .patch(api.assetTypeById(assetTypeId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to patch asset type ${assetTypeId}.`,
        );
      });
  },
  getAllAssetTypes() {
    return esApiV3
      .get(api.all())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get all asset types.',
        );
      });
  },
};

import { defineStore } from 'pinia';
import serviceCategoryService from '@/service/service-category.service';

export const useServiceCategoryStore = defineStore('serviceCategory', {
  state: () => ({
    serviceCategories: [],
    serviceCategoryGroups: [],
  }),
  actions: {
    async getServiceCategories() {
      if (this.serviceCategories.length === 0) {
        const results = await serviceCategoryService.getAllServiceCategories();
        this.serviceCategories = results.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
    },
    async getServiceCategoryGroups() {
      const groups = await serviceCategoryService.getServiceCategoryGroups();
      this.serviceCategoryGroups = groups;
    },
  },
  getters: {
    getServiceCategoryName: (state) => (id) =>
      state.serviceCategories.find((c) => c.id === id)?.name,
  },
});

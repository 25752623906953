import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  locations: () => '/locations',
  locationSummaries: () => '/locations/summaries',
  locationById: (locationId) => `/locations/${locationId}`,
  locationPmSummaryById: (locationId) => `/locations/${locationId}/pmSummary`,
  locationsForVendor: () => '/locations/viewableVendorLocations',
  goLiveSms: (locationId) => `/locations/${locationId}/sendGoLiveSms`,
};

export default {
  getLocationsByQuery(query) {
    return esApiV3
      .get(api.locations(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get locations by query.',
        );
      });
  },
  getLocationSummaries(query) {
    return esApiV3
      .get(api.locationSummaries(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get location summaries',
        );
      });
  },
  getLocationById(locationId, query) {
    return esApiV3
      .get(api.locationById(locationId), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while getting location: ${locationId}`,
        );
      });
  },
  getLocationPmSummaryById(locationId) {
    return esApiV3
      .get(api.locationPmSummaryById(locationId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while getting location PM summary: ${locationId}`,
        );
      });
  },
  updateLocation(locationId, body) {
    return esApiV3
      .patch(api.locationById(locationId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while updating location: ${locationId}`,
        );
      });
  },
  updateManyLocations(body) {
    return esApiV3
      .patch(api.locations(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while updating locations',
        );
      });
  },
  createLocation(body) {
    return esApiV3
      .post(api.locations(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create location.',
        );
      });
  },
  getLocationsForVendor(query) {
    return esApiV3
      .get(api.locationsForVendor(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while getting locations for vendor: ${query.vendorId}`,
        );
      });
  },
  sendGoLiveSms(locationId, { body, userProfileIds }) {
    return esApiV3
      .post(api.goLiveSms(locationId), { body, userProfileIds })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to send go live SMS.',
        );
      });
  },
};

import axios from 'axios';
import { useGlobalStore } from '@/stores/globalStore';

export default {
  async getVersion() {
    const versionDate = await axios.get('/version.json', {
      params: {
        time: new Date().toISOString(),
      },
    });
    return versionDate.data.raw;
  },
  async handleNewVersionAvailable() {
    const version = await this.getVersion();
    const currentVersion = localStorage.getItem('version');
    if (currentVersion === null) {
      window.localStorage.setItem('version', version);
    } else if (currentVersion !== version) {
      window.localStorage.setItem('version', version);
      const globalStore = useGlobalStore();
      globalStore.resetState();
      window.location.reload();
    }
  },
};

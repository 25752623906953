import { defineStore } from 'pinia';
import userProfilesService from '@/service/user-profiles.service';
import { useAuthStore } from './authStore';

export const useNavStore = defineStore('nav', {
  state: () => ({
    drawerIsOpen: false,
    drawerIsMini: true,
    darkMode: false,
  }),
  actions: {
    toggleDrawer(value) {
      this.drawerIsOpen = value;
      this.savePreference('drawerIsOpen');
    },
    setMiniStatus(value) {
      this.drawerIsMini = value;
      this.savePreference('drawerIsMini');
    },
    setDarkMode() {
      this.darkMode = !this.darkMode;
      this.savePreference('darkMode');
    },
    setPreferences(preferences) {
      this.drawerIsOpen = preferences.drawerIsOpen;
      this.drawerIsMini = preferences.drawerIsMini;
      this.darkMode = preferences.darkMode;
    },
    async savePreference(preference) {
      const authStore = useAuthStore();
      await userProfilesService.updateUserProfile(authStore.currentUserId, {
        isBohUser: true,
        bohPreferences: { [preference]: this[preference] },
      });
    },
  },
});

import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

const api = {
  areas: () => 'areas',
  allAreas: () => 'areas/all',
};

export default {
  getAllAreas() {
    return esApiV3
      .get(api.allAreas())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get all coverage areas',
        );
      });
  },
  saveNewArea(payload) {
    return esApiV3
      .post(api.areas(), payload)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to save new coverage area',
        );
      });
  },
};

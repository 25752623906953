import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  requestAttachments: () => 'request-attachments',
  requestAttachmentsById: (id) => `request-attachments/${id}`,
  smsRequests: (mediaId) => `request-attachments/smsRequests/${mediaId}`,
};

export default {
  getAttachmentsByQuery(query) {
    return esApiV3
      .get(api.requestAttachments(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get attachments.',
        );
      });
  },
  uploadAttachment(file, requestId, attachmentType, taskId) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('attachmentType', attachmentType);
    formData.append('requestId', requestId);
    if (taskId) formData.append('taskId', taskId);
    return esApiV3
      .post(api.requestAttachments(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => result.data)
      .catch((ex) => {
        try {
          exception.showCustomError(
            ex,
            'An error occurred while trying to upload file.',
          );
        } catch {
          // catch rethrow
        }
      });
  },
  updateAttachment(attachmentId, changes) {
    return esApiV3
      .patch(api.requestAttachmentsById(attachmentId), changes)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to update attachment.',
        );
      });
  },
  deleteAttachment(fileId, attachmentType) {
    return esApiV3
      .delete(api.requestAttachmentsById(fileId), { params: attachmentType })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to delete attachment.',
        );
      });
  },
  getRequestsAssociatedToSmsMedia(mediaIa) {
    return esApiV3
      .get(api.smsRequests(mediaIa))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to requests associated with attachment.',
        );
      });
  },
};

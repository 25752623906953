export const pmActivityTypes = {
  RESCHEDULE_VENDOR_VISIT: 'Reschedule Vendor Visit',
  SCHEDULE_VENDOR_VISIT: 'Schedule Vendor Visit',
  VENDOR_SCHEDULED_COMMUNICATION: 'Vendor Scheduled Communication',
  VENDOR_RESCHEDULED_COMMUNICATION: 'Vendor Rescheduled Communication',
  REQUEST_VENDOR_PROOF_OF_WORK_COMMUNICATION:
    'Request Vendor Proof of Work Communication',
  RECONCILE_PM_WORK: 'Reconcile PM Work',
  PM_RESOLUTION_EMAIL: 'PM Resolution Email',
  CONFIRM_NEXT_OCCURRENCE: 'Confirm Next Occurrence',
  AUTO_SCHEDULE_VENDOR_VISIT: 'Auto Schedule Vendor Visit',
};

export const autoSendActivityTypes = {
  DAY_OF_SERVICE_REMINDER_EMAIL: 'Day of Service Reminder Email',
  REQUEST_AUTO_CLOSE: 'Request Auto-Close',
};

export const externalActivityTypes = {
  INTERNAL_TECHNICIAN_RESOLUTION: 'Internal Technician Resolution',
};

export const telephonyActivityTypes = {
  INBOUND_CALL: 'Inbound Call',
  OUTBOUND_CALL: 'Outbound Call',
  SMS: 'SMS',
  CHAT: 'Chat',
};

export const taskTypes = {
  ADD_QUOTE: 'Add Quote',
  AUTOMATION_LOG: 'Automation Log',
  COMPLETE_ASSET: 'Complete asset',
  COMPLETE_VENDOR_RECORD: 'Complete Vendor Record',
  DISPATCH: 'Dispatch',
  DISPATCH_CONFIRMATION: 'Dispatch Confirmation',
  FOLLOW_UP: 'Follow up',
  LOG_SAVINGS: 'Log Savings',
  OTHER: 'Other',
  POST_SERVICE_VISIT_FOLLOW_UP: 'Post Service Visit Follow Up',
  PURCHASED_PARTS: 'Purchased parts',
  QUOTE: 'Quote',
  QUOTE_APPROVAL: 'Quote Approval',
  REQUEST_DISPATCH: 'Request Dispatch',
  REQUEST_QUOTE: 'Request Quote',
  RESOLUTION: 'Resolution',
  RESOLUTION_EMAIL: 'Resolution email',
  RESOLUTION_FOLLOW_UP: 'Resolution Follow Up',
  TRANSCRIBE_INVOICE: 'Transcribe Invoice',
  TROUBLESHOOTING: 'Troubleshooting',
  UPDATE_CUSTOMER: 'Update Customer',
  UPDATE_ETA_EMAIL: 'Update ETA Email',
  UPLOAD_INVOICE: 'Upload Invoice',
  VENDOR_DISPATCHED_EMAIL: 'Vendor Dispatched Email',
  VENDOR_FOLLOW_UP: 'Vendor Follow Up',
  VENDOR_QUESTIONNAIRE: 'Questionnaire',
  WARRANTY_CHECK: 'Warranty check',
};

export const allActivityTypes = {
  ...pmActivityTypes,
  ...autoSendActivityTypes,
  ...externalActivityTypes,
  ...telephonyActivityTypes,
  ...taskTypes,
  BLOCK_TASK: 'Block Task',
  CALL: 'Call',
  COMMUNICATION: 'Communication',
  CUSTOMER_UPDATE_EMAIL: 'Customer Update Email',
  DAILY_ACTIVITY_REPORT: 'Daily activity report',
  DEESCALATE_TASK: 'De-escalate Task',
  DISPATCH_APPROVAL: 'Dispatch Approval',
  ESCALATE_TASK: 'Escalate Task',
  REQUEST_DISPATCH_EMAIL: 'Request Dispatch Email',
  REQUEST_QUOTE_EMAIL: 'Request Quote Email',
  TEXT: 'Text',
  UNASSIGN_VENDOR: 'Unassign Vendor',
  UNBLOCK_TASK: 'Unblock Task',
  WEEKLY_ACTIVITY_REPORT: 'Weekly activity report',
  WEEKLY_ACTIVITY_REPORT_FOR_LOCATION_GROUP:
    'Weekly activity report for location tag',
  CUSTOMER_NOTE: 'Customer Note',
  SERVICE_VISIT_FOLLOW_UP: 'Service Visit Follow Up',
};

export const blockReasons = [
  'Incorrectly entered information',
  'Customer requested delay',
  'New service date',
  'Part of a batch follow up',
  'Waiting on Parts',
  'Waiting on Customer',
  'Waiting on Quote',
  'Waiting on Quote Approval',
  'Waiting on NTE increase',
  'Waiting on Vendor',
  'Other',
];

export const autoTaskBlockReasons = [
  'Vendor Communication (Auto)',
  'Vendor Communication No Reply Follow Up (Auto)',
];

export const unblockReasons = [
  'Required information updated',
  'Parts availability change',
  'Vendor ETA change',
  'Customer request',
  'Emergency escalation',
  'Other',
];

export const serviceCompleteExemptTaskTypes = [
  'Warranty check',
  'Transcribe Invoice',
  'Resolution Follow Up',
  'Complete Vendor Record',
  'Complete asset',
  'Log Savings',
  'Confirm Next Occurrence',
];

export const autoCloseEligibilityReason = {
  VENDOR_NOT_LIVE: 'Vendor is not live',
  VENDOR_IS_INTERNAL_TECHNICIAN: 'Vendor is internal technician',
  VENDOR_ETA_START_DATE_NOT_SET: 'Vendor ETA start date not set',
  REQUEST_CREATED_BY_INTERNAL_TECHNICIAN:
    'Request created by internal technician',
  LOCATION_NOT_LIVE: 'Location is not live',
  VENDOR_NOT_ASSIGNED: 'Vendor not assigned',
  ACTIVITY_CREATED: 'Activity created',
  TASK_CREATED: 'Task created',
  ETA_UPDATED: 'ETA updated',
  REQUIRES_FURTHER_ACTION: 'Requires further action',
  POST_VISIT_FOLLOW_UP_TASK_NOT_COMPLETED:
    'Post visit follow up task not completed',
};

import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  customers: () => 'customers',
  customerSummaries: () => 'customers/summaries',
  customerById: (id) => `customers/${id}`,
  pmCustomers: () => 'customers/pm',
  customerPmLocations: (id) => `customers/${id}/pm-locations`,
  customerVendorPaymentTerms: (id) => `customers/${id}/vendorPaymentTerms`,
  partnerships: () => 'customers/partnerships',
};

export default {
  getCustomersByQuery(query) {
    return esApiV3
      .get(api.customers(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get customers',
        );
      });
  },
  getCustomerSummaries(query) {
    return esApiV3
      .get(api.customerSummaries(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get customers',
        );
      });
  },
  getCustomerById(customerId) {
    return esApiV3
      .get(api.customerById(customerId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get customer ${customerId}`,
        );
      });
  },
  updateCustomer(customerId, body) {
    return esApiV3
      .patch(api.customerById(customerId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying update the customer.',
        );
      });
  },
  createCustomer(body) {
    return esApiV3
      .post(api.customers(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create customer.',
        );
      });
  },
  getPmCustomers(searchString) {
    return esApiV3
      .get(api.pmCustomers(), { params: { searchString } })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get pm customers.',
        );
      });
  },
  getCustomerPmLocations(customerId) {
    return esApiV3
      .get(api.customerPmLocations(customerId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get customer pm locations.',
        );
      });
  },
  getVendorsWithPaymentTermsForCustomer(customerId) {
    return esApiV3
      .get(api.customerVendorPaymentTerms(customerId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get vendors with payment terms for customer ${customerId}`,
        );
      });
  },
  getAllPartnerships() {
    return esApiV3
      .get(api.partnerships())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get all customer partnerships',
        );
      });
  },
};

import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  requests: () => 'requests',
  requestById: (id) => `requests/${id}`,
  quoteEstimate: (requestId) => `requests/${requestId}/quoteEstimate`,
  openDispatchRequestedByVendor: (vendorId) =>
    `requests/openDispatchRequested/${vendorId}`,
  forInvoiceGathering: () => 'requests/forInvoiceGathering',
  invoicePhaseChange: () => 'requests/markAsInvoicesNotApplicable',
  getDateForAutoClosure: (requestId) => `requests/${requestId}/autoCloseDate`,
  updateRequestPriority: (requestId) => `requests/${requestId}/priority`,
  updateRequestStatus: (requestId) => `requests/${requestId}/status`,
  initiateAutoClosure: (requestId) =>
    `requests/${requestId}/initiateAutoClosure`,
  getMagicIntakeLink: () => 'requests/magicIntakeLink',
  unarchiveRequest: (requestId) => `requests/unarchive/${requestId}`,
  getRequestIntakeFacts: () => 'facts/requestIntake',
  getRequestProjectedCost: (requestId) => `requests/${requestId}/projectedCost`,
  triggerVendorQuoteAction: (requestId) =>
    `requests/${requestId}/trigger-quote-action`,
};

export default {
  getRequestsByQuery(query) {
    return esApiV3
      .get(api.requests(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get requests.',
        );
      });
  },
  getRequestById(id, query) {
    return esApiV3
      .get(api.requestById(id), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get request ${id}.`,
        );
      });
  },
  updateRequest(requestId, body) {
    return esApiV3
      .patch(api.requestById(requestId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying update the request.',
        );
      });
  },
  createRequest(body) {
    return esApiV3
      .post(api.requests(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create a service request.',
        );
      });
  },
  deleteRequest(requestId, archivedReason) {
    return esApiV3
      .delete(api.requestById(requestId), { data: { archivedReason } })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to delete request ${requestId}.`,
        );
      });
  },
  unarchiveRequest(requestId) {
    return esApiV3
      .post(api.unarchiveRequest(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to unarchive request ${requestId}.`,
        );
      });
  },
  getQuoteReasonablenessData(requestId) {
    return esApiV3
      .get(api.quoteEstimate(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get quote estimate data for request ${requestId}.`,
        );
      });
  },
  getRequestProjectedCost(requestId) {
    return esApiV3
      .get(api.getRequestProjectedCost(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get projected cost for request ${requestId}.`,
        );
      });
  },
  getOpenDispatchRequestsByVendor(vendorId) {
    return esApiV3
      .get(api.openDispatchRequestedByVendor(vendorId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get dispatch request history for vendor: ${vendorId}`,
        );
      });
  },
  getRequestsForInvoiceGathering(params) {
    return esApiV3
      .get(api.forInvoiceGathering(), { params })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get requests for invoice gathering',
        );
      });
  },
  markInvoicesNaForGather(body) {
    return esApiV3
      .post(api.invoicePhaseChange(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to mark the requests as N/A for invoice gathering.',
        );
      });
  },
  getPmRequestHistory(assetId) {
    return esApiV3
      .get('requests/pmRequestHistory', { params: { assetId } })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get PM request history for asset: ${assetId}`,
        );
      });
  },
  getDateForAutoClosure(requestId) {
    return esApiV3
      .get(api.getDateForAutoClosure(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get auto-close eligibility for request ${requestId}.`,
        );
      });
  },
  updateRequestPriority(requestId, payload) {
    return esApiV3
      .post(api.updateRequestPriority(requestId), payload)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while updating request priority for request ${requestId}.`,
        );
      });
  },
  updateRequestStatus(requestId, payload) {
    return esApiV3
      .post(api.updateRequestStatus(requestId), payload)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while updating request status for request ${requestId}.`,
        );
      });
  },
  initiateAutoClosure(requestId, { date }) {
    return esApiV3
      .post(api.initiateAutoClosure(requestId), { date })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to initiate auto-close for request ${requestId}.`,
        );
      });
  },
  getMagicIntakeLink(payload) {
    return esApiV3
      .post(api.getMagicIntakeLink(), payload)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while generating a magic link for request intake.',
        );
      });
  },

  getRequestIntakeFacts(params) {
    return esApiV3
      .get(api.getRequestIntakeFacts(), { params })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while generating getting facts for request intake.',
        );
      });
  },
  triggerVendorQuoteAction(requestId, body) {
    return esApiV3
      .post(api.triggerVendorQuoteAction(requestId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to trigger a vendor quote action.',
        );
      });
  },
};

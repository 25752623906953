import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  activities: () => 'activities',
  activityById: (id) => `activities/${id}`,
  activityLogSummary: (requestId) =>
    `activities/activityLogSummary/${requestId}`,
};

export default {
  getActivitiesByQuery(query) {
    return esApiV3
      .get(api.activities(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get activities.',
        );
      });
  },
  updateActivity(id, description) {
    return esApiV3
      .patch(api.activityById(id), { description })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to patch activity ${id}.`,
        );
      });
  },
  createActivity(requestId, activity) {
    return esApiV3
      .post(api.activities(), { ...activity, requestId })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to save an activity for request ${requestId}.`,
        );
      });
  },
  deleteActivity(id) {
    return esApiV3
      .delete(api.activityById(id))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to delete activity ${id}.`,
        );
      });
  },
  getActivityLogSummary(requestId) {
    return esApiV3
      .get(api.activityLogSummary(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get activity log summary.',
        );
      });
  },
  getActivityById(id, relations) {
    return esApiV3
      .get(api.activityById(id), { params: { relations } })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get activity ${id}.`,
        );
      });
  },
};

import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  manufacturers: () => '/manufacturers',
  manufacturersForWarrantyReview: () => '/manufacturers/warrantyReview',
  manufacturerById: (id) => `/manufacturers/${id}`,
  manufacturersByCustomerId: (customerId) =>
    `/manufacturers/byCustomer/${customerId}`,
};

export default {
  getManufacturersByQuery(query) {
    return esApiV3
      .get(api.manufacturers(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while getting manufacturers by query',
        );
      });
  },
  getManufacturerById(manufacturerId) {
    return esApiV3
      .get(api.manufacturerById(manufacturerId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while getting manufacturer',
        );
      });
  },
  getManufacturersForWarrantyReview() {
    return esApiV3
      .get(api.manufacturersForWarrantyReview())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while getting manufacturers for warranty review',
        );
      });
  },
  getManufacturersByCustomerAssets(customerId) {
    return esApiV3
      .get(api.manufacturersByCustomerId(customerId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while getting manufacturers by query',
        );
      });
  },
  patchManufacturer(manufacturerId, body) {
    return esApiV3
      .patch(api.manufacturerById(manufacturerId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while updating manufacturer',
        );
      });
  },
  createManufacturer(body) {
    return esApiV3
      .post(api.manufacturers(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while creating manufacturer',
        );
      });
  },
};

import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  pmChoreTypes: () => 'pm-chore-types',
  pmChoreTypesById: (id) => `pm-chore-types/${id}`,
};

export default {
  getPmChoresByQuery(query) {
    return esApiV3
      .get(api.pmChoreTypes(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get PM chore types',
        );
      });
  },
  createPmChoreType(body) {
    return esApiV3
      .post(api.pmChoreTypes(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to save new PM Chore',
        );
      });
  },
  updatePmChoreType(choreId, body) {
    return esApiV3
      .patch(api.pmChoreTypesById(choreId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to patch chore ${choreId}.`,
        );
      });
  },
};

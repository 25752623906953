import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

const api = {
  notes: () => 'notes',
  noteById: (id) => `notes/${id}`,
  noteForRequest: (requestId) => `notes/forRequest/${requestId}`,
};

export default {
  getNotesByQuery(query) {
    return esApiV3
      .get(api.notes(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get notes.',
        );
      });
  },
  getNotesForRequest(requestId) {
    return esApiV3
      .get(api.noteForRequest(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get notes for request ${requestId}.`,
        );
      });
  },
  updateNote(noteId, body) {
    return esApiV3
      .patch(api.noteById(noteId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to update a note.',
        );
      });
  },
  createNote(body) {
    return esApiV3
      .post(api.notes(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create a new note.',
        );
      });
  },
  deleteNote(noteId) {
    return esApiV3
      .delete(api.noteById(noteId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to delete a note.',
        );
      });
  },
};

import esApiV3 from '@/service/esapiV3.service';
import exception from '@/service/exception.service';

const api = {
  allServiceCategories: () => '/service-categories/all',
  serviceCategoryGroups: () => '/service-categories/groups',
};

export default {
  getAllServiceCategories() {
    return esApiV3
      .get(api.allServiceCategories())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get all service categories',
        );
      });
  },
  getServiceCategoryGroups() {
    return esApiV3
      .get(api.serviceCategoryGroups())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get service category groups',
        );
      });
  },
};

import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

dayjs.extend(utcPlugin);

const api = {
  tasks: () => 'tasks',
  taskSummaries: () => 'tasks/summaries',
  distributeTasks: () => 'tasks/distribute',
  taskById: (taskId) => `tasks/${taskId}`,
  completeTask: (taskId) => `tasks/${taskId}/complete`,
  saveTask: (taskId) => `tasks/${taskId}/save`,
  getTaskTypes: () => 'tasks/types',
  blockTask: (taskId) => `tasks/${taskId}/block`,
  unblockTask: (taskId) => `tasks/${taskId}/unblock`,
  escalateTask: (taskId) => `tasks/${taskId}/escalate`,
  deescalateTask: (taskId) => `tasks/${taskId}/deescalate`,
  releaseTask: (taskId) => `tasks/${taskId}/release`,
  requestDispatch: () => 'tasks/requestDispatch/complete',
  taskSavings: (taskId) => `tasks/${taskId}/savings`,
};

export default {
  getTask(taskId, query) {
    return esApiV3
      .get(api.taskById(taskId), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get tasks.',
        );
      });
  },
  getTasksByQuery(query) {
    return esApiV3
      .get(api.tasks(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get tasks.',
        );
      });
  },
  getTaskSummaries(params) {
    return esApiV3
      .get(api.taskSummaries(), { params })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get tasks.',
        );
      });
  },
  updateTask(taskId, body) {
    return esApiV3
      .patch(api.taskById(taskId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to patch task ${taskId}.`,
        );
      });
  },
  createTask(newTask) {
    return esApiV3
      .post(api.tasks(), newTask)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create a new task.',
        );
      });
  },
  save86Task(body, taskId) {
    return esApiV3
      .post(api.saveTask(taskId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to save task with id: ${taskId}`,
        );
      });
  },
  completeTask(body, taskId) {
    return esApiV3
      .post(api.completeTask(taskId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to complete task with id: ${taskId}`,
        );
      });
  },
  getTaskTypes() {
    return esApiV3
      .get(api.getTaskTypes())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get task types for filter.',
        );
      });
  },
  blockTask(taskId, params) {
    return esApiV3
      .post(api.blockTask(taskId), params)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to block task.',
        );
      });
  },
  unblockTask(taskId, params) {
    return esApiV3
      .post(api.unblockTask(taskId), params)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to unblock task.',
        );
      });
  },
  escalateTask(taskId, params) {
    return esApiV3
      .post(api.escalateTask(taskId), params)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to escalate task.',
        );
      });
  },
  deescalateTask(taskId, params) {
    return esApiV3
      .post(api.deescalateTask(taskId), params)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to de-escalate task.',
        );
      });
  },
  releaseTask(taskId, params) {
    return esApiV3
      .post(api.releaseTask(taskId), params)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to release task.',
        );
      });
  },
  deleteTask(taskId) {
    return esApiV3
      .delete(api.taskById(taskId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to delete task',
        );
      });
  },
  distributeTasks(postBody) {
    return esApiV3
      .post(api.distributeTasks(), postBody)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to distribute tasks.',
        );
      });
  },
  requestDispatch(body) {
    return esApiV3
      .post(api.requestDispatch(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to distribute tasks.',
        );
      });
  },
  getTaskSavings(taskId) {
    return esApiV3
      .get(api.taskSavings(taskId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get task savings.',
        );
      });
  },
};

import { defineStore } from 'pinia';
import router from '@/router';
import vendorService from '@/service/vendor.service';
import assetService from '@/service/asset.service';
import pmQuotesService from '@/service/pm-quotes.service';
import noteService from '@/service/note.service';
import pmChoreService from '@/service/pm-chore-types.service';
import locationService from '@/service/location.service';
import { useServiceCategoryStore } from './serviceCategoryStore';

const defaultState = {
  currentStep: 1,
  isDuplicate: false,
  isLoading: false,
  location: null,
  selectedServiceCategories: [],
  vendor: null,
  contractApprovedBy: null,
  accessDetails: null,
  preferredService: [],
  dateReceived: null,
  costPerVisit: null,
  methodOfReceipt: null,
  frequencyUnit: null,
  frequencyNumber: 1,
  attachments: [],
  duplicateAttachmentsMetadata: [],
  assetByAssetId: {},
  newQuoteLocationAssetInfo: [],
  contractNotes: '',
  contractName: '',
  automateScheduling: false,
};

export const usePmQuoteStore = defineStore('pmQuote', {
  state: () => ({ ...defaultState }),
  actions: {
    setCurrentStep(value) {
      this.currentStep = value;
    },
    async updateContractName() {
      if (!this.location || !this.selectedServiceCategories || !this.vendor) {
        return;
      }
      const locationName = this.location.name;
      const serviceCategoryStore = useServiceCategoryStore();
      const serviceCats = this.selectedServiceCategories.map((cat) =>
        serviceCategoryStore.getServiceCategoryName(cat),
      );
      const vendorObject = await vendorService.getVendorById(this.vendor);
      const name = `${serviceCats.join(', ')} at ${locationName} with ${
        vendorObject?.name
      }`;
      this.contractName = name;
    },
    async getLocationAssetWithPMInfo(locationId) {
      const locationAssetWithPMInfo = await assetService.getPmAssetsByQuery({
        locationId,
      });
      this.newQuoteLocationAssetInfo = locationAssetWithPMInfo;
      return locationAssetWithPMInfo;
    },
    async saveNewPMQuote() {
      let attachmentsMetadata = [];
      if (this.isDuplicate) {
        attachmentsMetadata = this.duplicateAttachmentsMetadata;
      } else if (this.attachments.length > 0) {
        const formData = new FormData();
        this.attachments.forEach((attachment) => {
          formData.append('files', attachment);
        });
        attachmentsMetadata = await pmQuotesService.addPmQuoteAttachments(
          formData,
        );
      }
      const assetsToSave = Object.values(this.assetByAssetId)?.map((asset) => ({
        assetId: asset.id,
        chores: asset.selectedPmChoreIds.map((id) => ({ id })),
      }));
      const newQuote = {
        locationId: this.location.id,
        approvedBy: this.contractApprovedBy,
        accessDetails: this.accessDetails,
        serviceHourRanges: this.preferredService,
        receivedAt: this.dateReceived,
        pricePerVisit: this.costPerVisit,
        receiptMethod: this.methodOfReceipt,
        vendorId: this.vendor,
        attachments: attachmentsMetadata,
        serviceCategoryIds: this.selectedServiceCategories,
        pmSchedule: {
          repeatInterval: this.frequencyUnit,
          repeatEvery: this.frequencyNumber,
          pmScheduleAssets: assetsToSave,
          automateScheduling: this.automateScheduling,
        },
        description: this.contractName,
      };
      const result = await pmQuotesService.createPmQuote(newQuote);
      if (this.contractNotes.trim().length > 0) {
        await noteService.createNote({
          content: this.contractNotes,
          isPinned: true,
          noteType: 'PM Quote',
          pmQuoteId: result.id,
        });
      }
      Object.assign(this, defaultState);
      return result;
    },
    saveNewPmchore(pmchore) {
      return pmChoreService.createPmChoreType(pmchore);
    },
    async duplicateQuote(duplicateQuoteId) {
      function toFilePlaceHolder(attachments) {
        return attachments.map(
          (attachment) => new File([], attachment.fileName),
        );
      }
      try {
        const contract = await pmQuotesService.getPmQuoteById(duplicateQuoteId);
        this.isDuplicate = true;
        const location = await locationService.getLocationById(
          contract.locationId,
        );
        this.location = location;
        this.contractApprovedBy = contract.approvedBy;
        this.preferredService = contract.serviceHourRanges;
        this.dateReceived = contract.receivedAt;
        this.costPerVisit = contract.pricePerVisit;
        this.methodOfReceipt = contract.receiptMethod;
        this.frequencyUnit = contract.pmSchedule.repeatInterval;
        this.frequencyNumber = contract.pmSchedule.repeatEvery;
        this.attachments = toFilePlaceHolder(contract.attachments);
        this.duplicateAttachmentsMetadata = contract.attachments;
        this.contractName = contract.description;
      } catch (e) {
        router.push({ name: 'PageNotFound' });
      }
    },
  },
});

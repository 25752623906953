import { defineStore } from 'pinia';
import pmChoreService from '@/service/pm-chore-types.service';
import { getAllPaginatedItems } from '@/utils';
import locationService from '@/service/location.service';
import customerService from '@/service/customer.service';
import pmQuotesService from '@/service/pm-quotes.service';
import requestsService from '@/service/requests.service';

export const usePmStore = defineStore('pm', {
  state: () => ({
    pmCustomers: null,
  }),
  actions: {
    async patchPmQuote({ quoteId, changes }) {
      const updatedQuote = await pmQuotesService.updatePmQuote(
        quoteId,
        changes,
      );
      return updatedQuote;
    },
    async getLocationWithPmQuotes(locationId) {
      const location = await locationService.getLocationPmSummaryById(
        locationId,
      );
      return location;
    },
    async acceptPmQuote({ quoteId, body }) {
      await pmQuotesService.acceptPmQuote(quoteId, body);
    },
    async rejectPmQuote({ quoteId, body }) {
      await pmQuotesService.rejectPmQuote(quoteId, body);
    },
    async toggleChoresComplete({ requestId, body }) {
      await requestsService.updateRequest(requestId, body);
    },
    async archiveQuote(quoteId) {
      await pmQuotesService.archivePmQuote(quoteId);
    },
    async getPmCustomers(searchString) {
      const allPmCustomers = await customerService.getPmCustomers(searchString);
      this.pmCustomers = allPmCustomers;
    },
    async addAttachments({ quoteId, existingAttachments, newFiles }) {
      const formData = new FormData();
      newFiles.forEach((file) => {
        formData.append('files', file);
      });
      const attachmentsMetadata = await pmQuotesService.addPmQuoteAttachments(
        formData,
      );
      const updatedAttachments = [
        ...existingAttachments,
        ...attachmentsMetadata,
      ];
      const changes = {
        attachments: updatedAttachments,
      };
      await pmQuotesService.updatePmQuote(quoteId, changes);
      return updatedAttachments;
    },
    async getChoresForAssetType(assetTypeId) {
      const allChoreTypes = await getAllPaginatedItems(
        pmChoreService.getPmChoresByQuery,
        { assetTypeId },
      );
      return allChoreTypes;
    },
    async patchChore({ choreId, body }) {
      await pmChoreService.updatePmChoreType(choreId, body);
    },
  },
});

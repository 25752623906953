import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

const api = {
  pmQuotes: () => 'pm-quotes',
  pmQuoteById: (id) => `pm-quotes/${id}`,
  archivePmQuote: (id) => `pm-quotes/${id}/archive`,
  acceptPmQuote: (id) => `pm-quotes/${id}/accept`,
  rejectPmQuote: (id) => `pm-quotes/${id}/reject`,
  pmQuoteAttachments: () => 'pm-quotes/attachments',
};

export default {
  getPmQuotesByQuery(query) {
    return esApiV3
      .get(api.pmQuotes(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get pm quotes',
        );
      });
  },
  getPmQuoteById(quoteId) {
    return esApiV3
      .get(api.pmQuoteById(quoteId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get pm quote',
        );
      });
  },
  updatePmQuote(quoteId, body) {
    return esApiV3
      .patch(api.pmQuoteById(quoteId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to patch pm quote',
        );
      });
  },
  createPmQuote(body) {
    return esApiV3
      .post(api.pmQuotes(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to save new pm quote',
        );
      });
  },
  archivePmQuote(quoteId) {
    return esApiV3
      .patch(api.archivePmQuote(quoteId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to archive pm quote',
        );
      });
  },
  acceptPmQuote(quoteId, body) {
    return esApiV3
      .patch(api.acceptPmQuote(quoteId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to accept pm quote',
        );
      });
  },
  rejectPmQuote(quoteId, body) {
    return esApiV3
      .patch(api.rejectPmQuote(quoteId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to reject pm quote',
        );
      });
  },
  addPmQuoteAttachments(file) {
    return esApiV3
      .post(api.pmQuoteAttachments(), file)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to save attachments for pm quote',
        );
      });
  },
};

import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

const api = {
  vendors: () => 'vendors',
  vendorById: (vendorId) => `vendors/${vendorId}`,
  vendorCustomers: (vendorId) => `vendors/${vendorId}/customers`,
  rosterView: () => 'vendors/rosterView',
  vendorsWithInvoiceInfo: () => 'vendors/withInvoiceCollectionInfo',
  dispatchedForRequest: (requestId) =>
    `vendors/dispatchedForRequest/${requestId}`,
  vendorsForNewPmQuote: () => 'vendors/vendorsForNewPmQuote',
  duplicateVendors: () => 'vendors/checkDuplicates',
  allPossibleVendorsForRequest: () => 'vendors/allPossibleVendorsForRequest',
};

export default {
  getVendorsByQuery(query) {
    return esApiV3
      .get(api.vendors(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get vendors',
        );
      });
  },
  getVendorById(id, query) {
    return esApiV3
      .get(api.vendorById(id), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get vendor',
        );
      });
  },

  getVendorCustomers(id) {
    return esApiV3
      .get(api.vendorCustomers(id))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get vendor customers',
        );
      });
  },
  updateVendor(vendorId, body) {
    return esApiV3
      .patch(api.vendorById(vendorId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to patch vendor: ${vendorId}`,
        );
      });
  },
  checkVendorForDupes(vendorInfo) {
    return esApiV3
      .post(api.duplicateVendors(), vendorInfo)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to check for duplicate vendors',
        );
      });
  },
  saveNewVendor(body) {
    return esApiV3
      .post(api.vendors(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to save new vendor',
        );
      });
  },
  getVendorsForRosterViewByQuery(query) {
    return esApiV3
      .get(api.rosterView(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get vendors for roster view.',
        );
      });
  },
  getVendorsForInvoiceGather(params) {
    return esApiV3
      .get(api.vendorsWithInvoiceInfo(), { params })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get requests that need invoices.',
        );
      });
  },
  getDispatchedVendorsForRequest(requestId) {
    return esApiV3
      .get(api.dispatchedForRequest(requestId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get vendors dispatched for request ${requestId}.`,
        );
      });
  },
  getVendorsForNewPmQuote(query) {
    return esApiV3
      .get(api.vendorsForNewPmQuote(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get vendors for for new pm quote.',
        );
      });
  },
  getAllPossibleVendorsForRequest(query) {
    return esApiV3
      .get(api.allPossibleVendorsForRequest(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get all possible vendors for the request.',
        );
      });
  },
};

import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';
import analyticsService from '@/service/analytics.service';

const api = {
  userProfiles: () => 'user-profiles',
  userProfileById: (id) => `user-profiles/${id}`,
  allPossibleContacts: (locationId) =>
    `user-profiles/${locationId}/allPossibleContacts`,
  self: () => 'user-profiles/self',
  getAssigneesWithTasks: () => 'user-profiles/bohUserswithOpenTasks',
  searchByAssociation: () => 'user-profiles/associations',
  getDispatchApprovers: () => 'user-profiles/dispatchApprovers',
  getRequestDispatchApprovers: () => 'user-profiles/requestDispatchApprovers',
  availability: () => 'user-profiles/availability',
};

export default {
  getUserProfilesByQuery(query) {
    return esApiV3
      .get(api.userProfiles(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get user profiles',
        );
      });
  },
  getUserProfileById(userId, query) {
    return esApiV3
      .get(api.userProfileById(userId), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while getting user profile ${userId}`,
        );
      });
  },
  updateUserProfile(userId, body) {
    return esApiV3
      .patch(api.userProfileById(userId), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while updating user profile.',
        );
      });
  },
  updateManyUserProfiles(body) {
    return esApiV3
      .patch(api.userProfiles(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while updating user profiles.',
        );
      });
  },
  createUserProfile(body) {
    return esApiV3
      .post(api.userProfiles(), body)
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to create a new user.',
        );
      });
  },
  deleteUserProfile(userId) {
    return esApiV3
      .delete(api.userProfileById(userId))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to delete a user.',
        );
      });
  },
  getAllPossibleContactsByLocationId(id) {
    return esApiV3
      .get(api.allPossibleContacts(id))
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          `An error occurred while trying to get all possible contacts for location ${id}.`,
        );
      });
  },
  getUserInfo() {
    return esApiV3
      .get(api.self())
      .then((result) => result.data)
      .then((userInfo) => {
        analyticsService.setUserId(userInfo.id);
        analyticsService.setUserProperties({
          Name: userInfo.name,
          Email: userInfo.email,
        });
        return userInfo;
      })
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get user info.',
        );
      });
  },
  getAssigneesWithTasks() {
    return esApiV3
      .get(api.getAssigneesWithTasks())
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get assignees for filter.',
        );
      });
  },
  getContactsBySearchTerm(query) {
    return esApiV3
      .get(api.searchByAssociation(), {
        params: query,
      })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to search contacts',
        );
      });
  },

  getDispatchApproversByLocation(query) {
    return esApiV3
      .get(api.getDispatchApprovers(), {
        params: query,
      })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get dispatch approvers',
        );
      });
  },

  getDispatchApproversByRequest(query) {
    return esApiV3
      .get(api.getRequestDispatchApprovers(), {
        params: query,
      })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get dispatch approvers for request',
        );
      });
  },

  searchForDupes(query) {
    return esApiV3
      .get(api.availability(), {
        params: query,
      })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to search for duplicate info',
        );
      });
  },
};

<template>
  <v-app>
    <router-view></router-view>
    <EsSnackbar
      v-if="$route.name !== 'Login'"
      :color="snackbarColor"
      :parseHtml="true"
      :text="snackbarText"
      :visible="snackbarVisible"
      @reset="resetSnackbar" />
  </v-app>
</template>

<script>
import { useSnackbarStore } from '@/stores/snackbarStore';

export default {
  name: 'App',
  data: () => ({
    snackbarStore: useSnackbarStore(),
  }),
  computed: {
    snackbarColor() {
      return this.snackbarStore.snackbarColor;
    },
    snackbarText() {
      return this.snackbarStore.snackbarText;
    },
    snackbarVisible() {
      return this.snackbarStore.snackbarVisible;
    },
  },
  methods: {
    resetSnackbar() {
      return this.snackbarStore.resetSnackbar();
    },
  },
};
</script>

import { defineStore } from 'pinia';
import areasService from '@/service/area.service';

export const useAreaStore = defineStore('areas', {
  state: () => ({
    areas: [],
  }),
  actions: {
    async getAllAreas() {
      const result = await areasService.getAllAreas();
      this.areas = result;
    },
    async saveNewArea(payload) {
      await areasService.saveNewArea(payload);
    },
  },
  getters: {
    getAreaName: (state) => (id) =>
      state.areas?.find((area) => area.id === id)?.displayName,
  },
});

/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VuePluralize from 'vue-pluralize';
import * as esComponents from '@86-repairs/design-system';
import * as esGlobalComponents from '@86-repairs/global-components';
import * as Sentry from '@sentry/vue';
import * as validator from 'email-validator';
import VueFroala from 'vue-froala-wysiwyg';
import config from '@/config';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import s3Plugin from '@/plugins/s3';
import '@/filters/date-filters';
import '@/filters/phone-filters';
import '@/filters/currency-filters';
import esApiV3 from '@/service/esapiV3.service';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.config.devtools = true;

Object.keys(esComponents).forEach((component) => {
  Vue.component(component, esComponents[component]);
});

Object.keys(esGlobalComponents).forEach((component) => {
  Vue.component(component, esGlobalComponents[component]);
});

Vue.filter('numFormat', numFormat(numeral));
Vue.filter('capitalize', (value) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('limitTextDisplay', (value, amount) => {
  if (!value) return '';
  return `${value.substring(0, amount)}...`;
});

Vue.use(VuePluralize);
Vue.use(s3Plugin, { axios: esApiV3 });
Vue.use(VueFroala);

Sentry.init({
  Vue,
  enabled: ['production', 'stage'].includes(config.mode),
  environment: config.mode,
  release: config.sentryVersion,
  dsn: 'https://54b6c5fe93b844718211c94bff5ceee1@o4504555716673536.ingest.sentry.io/4504555815436288',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        'eightysix-api-stage.86repairs.com',
        'eightysix-api.86repairs.com',
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: config.mode === 'production' ? 0.1 : 0.25,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.0,
  ignoreErrors: ['Network Error', 'Request failed with status code 400'],
});

Vue.config.productionTip = false;

Vue.set(Vue.prototype, 'validator', validator);

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app');

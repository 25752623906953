import { defineStore } from 'pinia';
import userProfilesService from '@/service/user-profiles.service';

export const useContactListStore = defineStore('contactList', {
  state: () => ({
    contactList: {
      totalContacts: 0,
      contacts: [],
      page: 1,
      limit: 10,
      loading: false,
    },
    updatedContactId: undefined,
  }),
  actions: {
    async getUsers(query, reset = false) {
      this.contactList.loading = true;
      const results = await userProfilesService.getUserProfilesByQuery({
        ...query,
        orderBy: 'name',
        orderDirection: 'ASC',
        limit: this.contactList.limit,
        offset: reset
          ? 0
          : (this.contactList.page - 1) * this.contactList.limit,
        relations: [
          'locationSettings',
          'customerSettings',
          'vendors',
          'identities',
          'bohRoles',
          'sspRoles',
          'locationGroups',
          'pushTokens',
        ],
      });
      if (reset) {
        this.contactList.page = 1;
        this.contactList.totalContacts = results.count;
        this.contactList.contacts = results.data;
      } else {
        this.contactList.contacts.push(...results.data);
      }
      this.contactList.page += 1;
      this.contactList.loading = false;
    },
    updateContact({ index, result }) {
      this.contactList.contacts[index] = result;
      this.updatedContactId = result.id;
    },
    resetList() {
      this.contactList = {
        totalContacts: 0,
        contacts: [],
        page: 1,
        limit: 10,
        loading: false,
      };
      return this.contactList;
    },
  },
});

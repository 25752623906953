import { defineStore } from 'pinia';
import manufacturersService from '@/service/manufacturer.service';

export const useManufacturerStore = defineStore('manufacturer', {
  state: () => ({
    manufacturersForWarrantyReview: [],
  }),
  actions: {
    async getManufacturersForWarrantyReview() {
      const manufacturers =
        await manufacturersService.getManufacturersForWarrantyReview();
      this.manufacturersForWarrantyReview = manufacturers;
    },
  },
});

import exception from '@/service/exception.service';
import esApiV3 from '@/service/esapiV3.service';

const api = {
  requestVendorRatings: () => 'request-vendor-ratings',
};

export default {
  getRequestVendorRatingsByQuery(query) {
    return esApiV3
      .get(api.requestVendorRatings(), { params: query })
      .then((result) => result.data)
      .catch((ex) => {
        exception.showCustomError(
          ex,
          'An error occurred while trying to get request vendor ratings',
        );
      });
  },
};

import Vue from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import businessDays from 'dayjs-business-days';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(businessDays);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

Vue.filter('YYYYMMDD', (value) => {
  if (!value) return '';

  return dayjs(value).utc().format('YYYY-MM-DD');
});

Vue.filter('YYYYMMDDlocal', (value) => {
  if (!value) return '';

  return dayjs(value).format('YYYY-MM-DD');
});

Vue.filter('MMDDYYYY', (value) => {
  if (!value) return '';

  return dayjs(value).utc().format('MM/DD/YYYY');
});

Vue.filter('MMDDYYYYlocal', (value) => {
  if (!value) return '';

  return dayjs(value).format('MM/DD/YYYY');
});

Vue.filter('MMDDYY', (value) => {
  if (!value) return '';

  return dayjs(value).format('MM/DD/YY');
});

Vue.filter('dddMMMD', (value) => {
  if (!value) return '';
  return dayjs(value).format('ddd, MMM D');
});

Vue.filter('MMMDDYYYY', (value) => {
  if (!value) return '';
  return dayjs(value).format('MMM DD, YYYY');
});

Vue.filter('MMDDYYYYHHMMA', (value) => {
  if (!value) return '';
  return dayjs(value).format('MM/DD/YYYY, hh:mm A');
});

Vue.filter('HHMM', (value) => {
  if (!value) return '';
  return dayjs(value).format('hh:mm');
});

Vue.filter('HHMMA', (value) => {
  if (!value) return '';
  return dayjs(value).format('hh:mm A');
});

Vue.filter('hhA', (value) => {
  if (!value) return '';
  return dayjs().hour(value).format('hh A');
});

Vue.filter('expirationDate', (value) => {
  const isValid = dayjs(value).isValid() && value !== undefined;
  return isValid ? dayjs(value).format('MM/DD/YYYY') : '(No Expiration Date)';
});

Vue.filter('ddddMMMMDDYYYY', (value) => {
  if (!value) return '';
  return dayjs(value).format('dddd, MMMM DD, YYYY');
});

Vue.filter('ddddMMMMDDYYYYv2', (value) => {
  if (!value) return '';
  return dayjs(value).format('dddd, MM/DD/YYYY');
});

Vue.filter('dddMMMDDhhmmA', (value) => {
  if (!value) return '';
  return dayjs(value).format('ddd, MMM DD, hh:mmA');
});

Vue.filter('dddMMMDDYYYYhhmmA', (value) => {
  if (!value) return '';
  return dayjs(value).format('ddd, MMM DD, YYYY, [at] hh:mmA');
});

Vue.filter('YYYYMMDDHHmmss', (value) => {
  if (!value) return '';
  return dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ');
});

Vue.filter('MMMM', (value) => {
  if (!value) return '';
  return dayjs(value).format('MMMM');
});

Vue.filter('secondsToHumanizedDuration', (value) => {
  if (!value) return '';
  return dayjs.duration(-Math.abs(value), 'seconds').humanize(true);
});

Vue.filter('durationFromNow', (value) => {
  if (!value) return '';
  const durationFromNow = dayjs.duration(dayjs().diff(dayjs(value)));
  const days = durationFromNow.days();
  const hours = durationFromNow.hours();
  const minutes = durationFromNow.minutes();
  const seconds = durationFromNow.seconds();
  const unitsOfTime = [];
  if (days) {
    unitsOfTime.push(`${days}d`);
  }
  if (hours || unitsOfTime.length > 0) {
    unitsOfTime.push(`${hours}h`);
  }
  if (minutes || unitsOfTime.length > 0) {
    unitsOfTime.push(`${minutes}m`);
  }
  unitsOfTime.push(`${seconds}s`);

  return unitsOfTime.join(' ');
});

Vue.filter('baseDate', (value) => {
  if (!value) return '';
  return dayjs(value).format();
});

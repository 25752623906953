import Vue from 'vue';

Vue.filter('USD', (value) => {
  if (Number.isNaN(value)) return value;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
});
